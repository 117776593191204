import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';
import * as COLORS from 'themes/colors';

export const fetchStartup = (user, site) => {
    return {
        type: CONSTANTS.FETCH_STARTUP,
        payload: {
            user,
            site
        }
    };
};

export const showMessage = (message, type = 'error', title) => {
    return {
        type: CONSTANTS.SHOW_MESSAGE,
        payload: {
            message,
            title,
            type: type
        }
    };
};

export const hideMessage = () => {
    return {
        type: CONSTANTS.HIDE_MESSAGE
    };
};

export const fetchDashboardWidgets = () => {
    return {
        type: CONSTANTS.FETCH_DASHBOARD_WIDGETS,
        payload: {
            url: '/self/dashboard/widget/read/',
            data: {}
        }
    };
};

export const updateDashboardWidgets = (data) => {
    return {
        type: CONSTANTS.UPDATE_DASHBOARD_WIDGETS,
        payload: {
            url: '/self/dashboard/widget/update/',
            data
        }
    };
};

export function toggleCollapsedNav(isNavCollapsed) {
    return { type: CONSTANTS.TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function updateWindowWidth(width) {
    return { type: CONSTANTS.WINDOW_WIDTH, width };
}

export function updateWindowHeight(height) {
    return { type: CONSTANTS.WINDOW_HEIGHT, height };
}

export function setLanguage(locale) {
    return {
        type: CONSTANTS.SET_LANGUAGE,
        payload: locale
    };
}

export const setFilter = (list, action) => {
    return {
        type: CONSTANTS.SET_FILTER,
        payload: {
            list,
            action
        }
    };
};

export const setLoader = (show) => {
    return {
        type: CONSTANTS.SET_LOADER,
        payload: show
    };
};

export const setFirmwareLoader = (show) => {
    return {
        type: CONSTANTS.SET_FIRMWARE_LOADER,
        payload: show
    };
};

export const setPerformanceLoader = (show) => {
    return {
        type: CONSTANTS.SET_PERFORMANCE_LOADER,
        payload: show
    };
};

export const fetchTheme = (companyUUID) => {
    return {
        type: CONSTANTS.FETCH_THEME,
        payload: {
            url: ENDPOINTS.ENDPOINT_THEME,
            data: companyUUID
        }
    };
};

export const setThemeColors = (colors = COLORS.light) => {
    return {
        type: CONSTANTS.SET_THEME_COLORS,
        colors: JSON.parse(colors)
    };
};

export const setTheme = (theme) => {
    return {
        type: CONSTANTS.SET_THEME,
        theme
    };
};

export const fetchIpLocation = () => {
    return {
        type: CONSTANTS.FETCH_IP_LOCATION
    };
};

export const setIpLocation = (data) => {
    return {
        type: CONSTANTS.SET_IP_LOCATION,
        payload: data
    };
};

export const setCountry = (data) => {
    return {
        type: CONSTANTS.SET_COUNTRY,
        payload: data
    };
};

export const fetchErrorDetails = (data) => {
    return {
        type: CONSTANTS.FETCH_ERROR_DETAILS,
        payload: {
            url: '/error/read/',
            data
        }
    };
};

export const fetchErrorDetailsDisplay = (data) => {
    return {
        type: CONSTANTS.FETCH_ERROR_DETAILS_DISPLAY,
        payload: {
            url: '/error/read/',
            data
        }
    };
};

export const setErrorDetails = (data) => {
    return {
        type: CONSTANTS.SET_ERROR_DETAILS,
        payload: data
    };
};

export const setErrorDetailsDisplay = (data) => {
    return {
        type: CONSTANTS.SET_ERROR_DETAILS_DISPLAY,
        payload: data
    };
};

export const submitErrorDetails = (data) => {
    return {
        type: CONSTANTS.SUBMIT_ERROR_DETAILS,
        payload: {
            url: '/error/submit/',
            data
        }
    };
};

export const fetchAllErrors = (data) => {
    return {
        type: CONSTANTS.FETCH_ALL_ERRORS,
        payload: {
            url: '/error/read/all/',
            data
        }
    };
};

export const setAllErrors = (data) => {
    return {
        type: CONSTANTS.SET_ALL_ERRORS,
        payload: data
    };
};

export const setErrorHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ERROR_HEADERS,
        payload: data
    };
};

export const updateError = (data) => {
    return {
        type: CONSTANTS.UPDATE_ERROR,
        payload: {
            url: '/error/update/',
            data
        }
    };
};

export const deleteError = (data) => {
    return {
        type: CONSTANTS.DELETE_ERROR,
        payload: {
            url: '/error/delete/',
            data
        }
    };
};

export const fetchWeather = (data) => {
    return {
        type: CONSTANTS.FETCH_WEATHER,
        payload: {
            data
        }
    };
};

export const setWeather = (data) => {
    return {
        type: CONSTANTS.SET_WEATHER,
        payload: data
    };
};

export const fetchWeatherWeek = (data) => {
    return {
        type: CONSTANTS.FETCH_WEATHER_WEEK,
        payload: {
            data
        }
    };
};

export const setWeatherWeek = (data) => {
    return {
        type: CONSTANTS.SET_WEATHER_WEEK,
        payload: data
    };
};

export const setSwitcherLoader = (data) => {
    return {
        type: CONSTANTS.SET_SWITCHER_LOADER,
        payload: data
    };
};

export const setDashboardWidgets = (data) => {
    return {
        type: CONSTANTS.SET_DASHBOARD_WIDGETS,
        payload: data
    };
};

export const setAvailableWidgets = (data) => {
    return {
        type: CONSTANTS.SET_AVAILABLE_WIDGETS,
        payload: data
    };
};

export const fetchOutageStatus = () => {
    return {
        type: CONSTANTS.FETCH_OUTAGE_STATUS,
        payload: {
            url: '/outage/read/',
            data: {}
        }
    };
};

export const updateOutageStatus = (outage, now) => {
    return {
        type: CONSTANTS.UPDATE_OUTAGE_STATUS,
        payload: {
            url: '/outage/update/',
            data: {
                outage: outage,
                forceNow: now
            }
        }
    };
};

export const fetchFacilityFeedback = () => {
    return {
        type: CONSTANTS.FETCH_FACILITY_FEEDBACK,
        payload: {
            url: '/feedback/get/facility/',
            data: {
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
            }
        }
    };
};

export const setFacilityFeedback = (data) => {
    return {
        type: CONSTANTS.SET_FACILITY_FEEDBACK,
        payload: data
    };
};

export const fetchAirQuality = (data) => {
    return {
        type: CONSTANTS.FETCH_AIR_QUALITY,
        payload: {
            data
        }
    };
};

export const setAirQuality = (data) => {
    return {
        type: CONSTANTS.SET_AIR_QUALITY,
        payload: data
    };
};

export const setTestSuite = (data) => {
    return {
        type: CONSTANTS.SET_TEST_SUITE,
        payload: data
    };
};

export const setSaveLoader = (data) => {
    return {
        type: CONSTANTS.SET_SAVE_LOADER,
        payload: data
    };
};

export const setFeedbackHeaders = (data) => {
    return {
        type: CONSTANTS.SET_FEEDBACK_HEADERS,
        payload: data
    };
};

export const fetchSiteVersion = () => {
    return {
        type: CONSTANTS.FETCH_SITE_VERSION,
        payload: {
            url: '/nse/router/site/read/',
            data: {}
        }
    };
};

export const setSiteVersion = (data) => {
    return {
        type: CONSTANTS.SET_SITE_VERSION,
        payload: data
    };
};

// fetches sites for non support sites page
export const fetchUserSiteList = (data) => {
    return {
        type: CONSTANTS.FETCH_USER_SITE_LIST,
        payload: {
            url: '/user/sitelist/',
            data
        }
    };
};

export const setUserSiteList = (data) => {
    return {
        type: CONSTANTS.SET_USER_SITE_LIST,
        payload: data
    };
};

export const setShowPortalSearch = (data) => {
    return { 
        type: CONSTANTS.SET_SHOW_PORTAL_SEARCH,
        payload: data
    }
}

export const setWebhooksSiteDetails = (data) => {
    return {
        type: CONSTANTS.SET_WEBHOOKS_SITE_DETAILS,
        payload: data
    }
}

export const setWebhooksClientDetails = (data) => {
    return {
        type: CONSTANTS.SET_WEBHOOKS_CLIENT_DETAILS,
        payload: data
    }
}

export const fetchWebhooksSiteDetails = (data) => {
    return {
        type: CONSTANTS.FETCH_WEBHOOKS_SITE_DETAILS,
        payload: {
            url: '/webhooks/v1/site/read/',
            data
        }
    }
}

export const fetchWebhooksClientDetails = (data) => {
    return {
        type: CONSTANTS.FETCH_WEBHOOKS_CLIENT_DETAILS,
        payload: {
            url: '/webhooks/v1/client/read/',
            data
        }
    }
}

export const setLoginPerformance = (data) => {
    return {
        type: CONSTANTS.SET_LOGIN_PERFORMANCE,
        payload: data
    }
}

export const submitLoginPerformance = (data) => {
    return {
        type: CONSTANTS.SUBMIT_LOGIN_PERFORMANCE,
        payload: {
            url: '/performance/login/',
            data
        }
    }
}

export const submitLoginPerformanceFailed = (data) => {
    return {
        type: CONSTANTS.SUBMIT_LOGIN_PERFORMANCE_FAILED,
        payload: {
            url: '/performance/login/failed/',
            data
        }
    }
}

export const submitUnlockPerformance = (data) => {
    return {
        type: CONSTANTS.SUBMIT_UNLOCK_PERFORMANCE,
        payload: {
            url: '/performance/unlock/',
            data
        }
    }
}

export const fetchPerformanceFilters = (data) => {
    return {
        type: CONSTANTS.FETCH_PERFORMANCE_FILTERS,
        payload: {
            url: '/performance/report/filter/',
            data: {}
        }
    }
}

export const fetchPerformanceStats = (data, origin) => {
    return {
        type: CONSTANTS.FETCH_PERFORMANCE_STATS,
        payload: {
            url: '/performance/report/stats/',
            data,
            origin
        }
    }
}

export const fetchPerformanceDetails = (data) => {
    return {
        type: CONSTANTS.FETCH_PERFORMANCE_DETAILS,
        payload: {
            url: '/performance/report/details/',
            data
        }
    }
}

export const fetchBatteryPerformanceDetails = (data) => {
    return {
        type: CONSTANTS.FETCH_BATTERY_PERFORMANCE_DETAILS,
        payload: {
            url: '/performance/report/battery/',
            data
        }
    }
}

export const submitPerformanceFilters = (data, recentFilter) => {
    return {
        type: CONSTANTS.SUBMIT_PERFORMANCE_FILTERS,
        payload: {
            url: '/performance/report/filter/',
            data,
            recentFilter
        }
    }
}

export const setPerformanceFilters = (data) => {
    return {
        type: CONSTANTS.SET_PERFORMANCE_FILTERS,
        payload: data
    }
}

export const setPerformanceStats = (data) => {
    return {
        type: CONSTANTS.SET_PERFORMANCE_STATS,
        payload: data
    }
}

export const setPerformanceDetails = (data) => {
    return {
        type: CONSTANTS.SET_PERFORMANCE_DETAILS,
        payload: data
    }
}

export const setBatteryPerformanceDetails = (data) => {
    return {
        type: CONSTANTS.SET_BATTERY_PERFORMANCE_DETAILS,
        payload: data
    }
}

export const setActiveFleetMgmt = (data) => {
    return {
        type: CONSTANTS.SET_FLEET_MGMT_ACTIVE,
        payload: data
    };
};

export const submitNotificationPerformance = (data) => {
    return {
        type: CONSTANTS.SUBMIT_NOTIFICATION_PERFORMANCE,
        payload: {
            url: data.notification_type === 'password_reset' ? '/performance/notification/notoken/' : '/performance/notification/',
            data
        }
    }
}

export const fetchSitesFilters = () => {
    return {
        type: CONSTANTS.FETCH_SITES_FILTERS,
        payload: {
            url: '/sites/filter/',
            data: {}
        }
    }
}

export const setSitesFilters = (data) => {
    return {
        type: CONSTANTS.SET_SITES_FILTERS,
        payload: data
    }
}

export const fetchDeviceFirmware = (data) => {
    return {
        type: CONSTANTS.FETCH_DEVICE_FIRMWARE,
        payload: {
            url: '/report/locks/firmware',
            data
        }
    }
}

export const fetchDeviceFirmwareFiltered = (data) => {
    return {
        type: CONSTANTS.FETCH_DEVICE_FIRMWARE_FILTERED,
        payload: {
            url: '/report/locks/firmware/filtered/',
            data
        }
    }
}

export const setDeviceFirmware = (data) => {
    return {
        type: CONSTANTS.SET_DEVICE_FIRMWARE,
        payload: data
    }
}

export const fetchDeviceVoltage = (data) => {
    return {
        type: CONSTANTS.FETCH_DEVICE_VOLTAGE,
        payload: {
            url: '/report/locks/voltage',
            data
        }
    }
}

export const setDeviceVoltage = (data) => {
    return {
        type: CONSTANTS.SET_DEVICE_VOLTAGE,
        payload: data
    }
}

export const fetchFirmwareAutoupdate = (data) => {
    return {
        type: CONSTANTS.FETCH_FIRMWARE_AUTOUPDATE,
        payload: {
            url: '/site/autoUpdate/firmware/get',
            data
        }
    }
}

export const setFirmwareAutoupdate = (data) => {
    return {
        type: CONSTANTS.SET_FIRMWARE_AUTOUPDATE,
        payload: data
    }
}

export const submitFirmwareAutoupdate = (data) => {
    return {
        type: CONSTANTS.SUBMIT_FIRMWARE_AUTOUPDATE,
        payload: {
            url: '/site/autoUpdate/firmware/setTargetVersion',
            data
        }
    }
}