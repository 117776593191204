import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { fadeInLeft } from 'react-animations';

import * as ICONS from 'utilities/icons';

import PMS from 'components/PMS';
class MenuList extends React.Component {
    constructor(props) {
        super(props);
    }
    isActive = (page) => {
        const { location } = this.props;
        let locationFirst = location.pathname.split('/')[1];
        let locationSecond = location.pathname.split('/')[2];

        // check for non support sites page
        if (page === 'user/facilities' && location.pathname.includes('app/user/facilities/all')) {
            return true;
        }
        if (location.pathname.includes('app/users/view')) {
            locationSecond = 'view';
        }
        return (locationFirst && locationFirst.includes(page)) || (locationSecond && locationSecond.includes(page));
    };
    checkPermissions = (permission, sub) => {
        const { authUser } = this.props;
        if (sub && authUser.permissions.includes(sub)) {
            return false;
        }
        if (!permission || permission.some(p => authUser.permissions.includes(p))) {
            return true;
        }
        return false;
    };
    checkFeature = (feature) => {
        const { authUser } = this.props;
        if (
            !feature ||
            (authUser && authUser.featureflagAssignments && authUser.featureflagAssignments.includes(feature))
        ) {
            return true;
        }
        return false;
    };
    checkKeypad = (keypad) => {
        const { site } = this.props;
        if (keypad && site && (!site.has3K && !site.has4K)) {
            return false;
        }
        return true;
    };
    render() {
        const { intl, authUser, theme, site, pms, height } = this.props;
        this.styles = styles(theme);
        let userName = authUser && `${authUser.firstName} ${authUser.lastName}`;
        const list = [
            {
                id: 'pages.home.TYPE',
                name: 'home/all',
                reference: 'home',
                Icon: ICONS.HomeIcon
            },
            {
                id: 'pages.sites.TYPES',
                name: 'facilities/all',
                reference: 'facilities',
                Icon: ICONS.SiteIcon,
                permission: ['support_view_all_companies']
            },
            {
                id: 'pages.sites.TYPES',
                name: 'user/facilities',
                reference: 'user/facilities',
                Icon: ICONS.SiteIcon,
                subPermission: 'support_view_all_companies'
            },
            {
                id: 'pages.reports.TYPES',
                name: 'reports/all',
                reference: 'reports',
                Icon: ICONS.ReportsIcon,
                permission: ['view_suspicious']
            },
            {
                id: 'pages.units.TYPES',
                name: 'units/all',
                reference: 'units',
                Icon: ICONS.UnitIcon,
                permission: ['view_site_locks']
            },
            {
                id: 'pages.users.TYPES',
                name: 'users/all',
                reference: 'users',
                Icon: ICONS.UserIcon,
                permission: ['manage_users']
            },
            {
                id: 'pages.entry.TYPES',
                name: 'entry/all',
                reference: 'entry',
                Icon: ICONS.EntryIcon,
                permission: ['view_entries']
            },
            {
                id: 'pages.accessCodes.TYPES',
                name: 'accessCodes/all',
                reference: 'accessCodes',
                Icon: ICONS.KeypadsIcon,
                permission: ['support_get_access_codes'],
                keypad: true
            },
            {
                id: 'pages.network.TYPES',
                name: 'network/all',
                reference: 'network',
                Icon: ICONS.GatewayIcon,
                permission: ['manage_gateways']
            },
            {
                id: 'pages.activity.TYPE',
                name: 'activity/all',
                reference: 'activity',
                Icon: ICONS.ActivityIcon
            },
            {
                id: 'pages.fleetmgmt.TYPES',
                name: 'fleetmgmt',
                reference: 'fleetmgmt',
                Icon: ICONS.DigitalAuditIcon,
                permission: ['digital_audit_final_approver']
            },
            {
                id: 'form.cameras',
                name: 'cameras',
                reference: 'cameras',
                Icon: ICONS.CameraIcon,
                permission: ['view_security_cameras'],
                feature: 'view_security_feed'
            },
            {
                id: 'pages.settings.TYPES',
                name: 'settings',
                reference: 'settings',
                Icon: ICONS.SettingsIcon
            },
            {
                id: 'pages.hours.Type',
                name: 'hours',
                reference: 'hours',
                Icon: ICONS.ScheduleIcon,
                permission: ['manage_sites']
            },
            {
                id: 'pages.maps.TYPES',
                name: 'maps/all',
                reference: 'maps',
                Icon: ICONS.SitemapIcon,
                permission: ['view_site_maps'],
                feature: 'site_map'
            },
            {
                id: 'pages.help.TYPE',
                name: 'help',
                reference: 'help',
                Icon: ICONS.HelpIcon
            },
            {
                id: 'pages.logout.TYPE',
                name: 'logout',
                reference: 'logout',
                Icon: ICONS.LoginIcon
            }
        ];
        if (
            authUser &&
            authUser.role &&
            authUser.role.tier &&
            authUser.role.tier > 0 &&
            authUser.role.tier != 1000 &&
            authUser.role.tier != 5
        ) {
            list.splice(9, 0, {
                //new view tab
                id: 'form.simpleView',
                name: 'users/view',
                reference: 'view',
                Icon: ICONS.ViewIcon
            });
        }
        return (
            <ul className={`nav-menu ${css(this.styles.wrapper, this.styles.fadeInLeft)}`}>
                <div
                    className={css(this.styles.profileWrapper)}
                    onClick={() => this.props.history.push(`/app/users/display/${authUser.uuid}`)}>
                    <div className={css(this.styles.avatarWrapper)}>
                        <div className={css(this.styles.avatar)}>
                            <ICONS.UserIcon
                                color={
                                    theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                                        ? theme.color.fontColor
                                        : theme.color.themeColor
                                }
                                width={height > 800 ? 15 : 12}
                                height={height > 800 ? 15 : 12}
                                fill={
                                    theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                                        ? theme.color.compColor
                                        : theme.color.themeColor
                                }
                            />
                        </div>
                    </div>
                    <div className={css(this.styles.avatarName)}>
                        {userName && userName.split('').length < 17
                            ? userName
                            : `${userName.split('').splice(0, 16).join('')}..`}
                        <br />
                        <span className={css(this.styles.avatarType)}>
                            {authUser.role && authUser.role.name
                                ? authUser.role.name
                                : intl.formatMessage({ id: `db.${authUser.type}` })}
                        </span>
                    </div>
                </div>
                {list.map((item) => {
                    let Icon = item.Icon;
                    let Component = item.Component;
                    return (
                        this.checkPermissions(item.permission, item.subPermission) &&
                        this.checkFeature(item.feature) &&
                        this.checkKeypad(item.keypad) && (
                            <div key={item.id}>
                                {item.hr && <hr className={css(this.styles.hr)} />}
                                <li className={css(this.styles.list)}>
                                    {Component ? (
                                        <Component Icon={item.Icon} />
                                    ) : (
                                        <NavLink to={`/app/${item.name}`} id={item.id}>
                                            <div className={css(this.styles.linkItem)}>
                                                <div className={css(this.styles.icons)}>
                                                    <Icon
                                                        color={
                                                            this.isActive(item.reference)
                                                                ? theme.color.themeColor
                                                                : theme.color.fontColor
                                                        }
                                                        width={height > 800 ? 20 : 15}
                                                        height={height > 800 ? 20 : 15}
                                                    />
                                                </div>
                                                <div className={css(this.styles.block)}>
                                                    <span
                                                        className={css(
                                                            this.isActive(item.reference)
                                                                ? this.styles.activeNav
                                                                : this.styles.link
                                                        )}
                                                        title={
                                                            intl.formatMessage({ id: item.id }).length >= 15
                                                                ? intl.formatMessage({ id: item.id })
                                                                : null
                                                        }>
                                                        {intl.formatMessage({ id: item.id }).length < 15
                                                            ? intl.formatMessage({ id: item.id })
                                                            : `${intl
                                                                  .formatMessage({ id: item.id })
                                                                  .split('')
                                                                  .splice(0, 14)
                                                                  .join('')}...`}{' '}
                                                        <div
                                                            className={css(
                                                                this.isActive(item.reference)
                                                                    ? this.styles.activeDot
                                                                    : this.styles.dot
                                                            )}>
                                                            <div
                                                                className={css(
                                                                    this.isActive(item.reference)
                                                                        ? this.styles.activeDotPulse
                                                                        : null
                                                                )}></div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )}
                                </li>
                            </div>
                        )
                    );
                })}
                {((pms && site.pms !== 'gds' && site.pms !== 'webhooks') ||
                    (pms && site.pms === 'webhooks' && authUser.role.tier === 1)) && (
                    <li className={css(this.styles.sync)}>
                        <PMS />
                    </li>
                )}
            </ul>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, height } = app;
    const { site, pms } = auth;
    return { theme, site, pms, height };
};

export default injectIntl(withRouter(connect(mapStateToProps)(MenuList)));

const translateKeyframes = {
    '0%': {
        transform: 'scale(0.1, 0.1)',
        opacity: 0
    },
    '50%': {
        opacity: 0.8
    },
    '100%': {
        transform: 'scale(1.6, 1.6)',
        opacity: 0
    }
};

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.menuColor,
            color: theme.color.fontColor,
            marginTop: 17
        },
        list: {
            padding: `${'.65vh' || 10} 17px`,
            '@media(max-height:800px)': {
                padding: `${'.35vh' || 6} 17px`
            }
        },
        sync: {
            padding: `${'.65vh' || 10} 13px ${'.65vh' || 10} 17px`,
            '@media(max-height:800px)': {
                padding: `${'.35vh' || 6} 13px ${'.35vh' || 6} 17px`
            }
        },
        listUser: {
            padding: `9px 20px 2px 20px`,
            '@media(max-height:800px)': {
                padding: `10px 20px 0px 20px`
            }
        },
        icons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            float: 'left'
        },
        link: {
            color: theme.color.fontColor,
            fontWeight: 400,
            marginLeft: 10,
            fontSize: 12,
            fontFamily: 'Poppins',
            ':hover': {
                opacity: 0.8,
                pointer: 'cursor'
            },
            '@media(max-height:800px)': {
                fontSize: 10
            }
        },
        activeNav: {
            marginLeft: 10,
            fontSize: 12,
            color: theme.color.fontColor,
            fontWeight: 400,
            fontFamily: 'Poppins',
            '@media(max-height:800px)': {
                fontSize: 10
            }
        },
        linkItem: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        hr: {
            borderTop: `solid 1px ${theme.color.borderColor}`,
            margin: `${'.5vh' || 5} 20px`
        },
        block: {
            display: 'inline-table',
            textTransform: 'uppercase'
        },
        fadeInLeft: {
            animationName: fadeInLeft,
            animationDuration: '1.2s'
        },
        activeDot: {
            height: 5,
            width: 5,
            background: theme.color.themeColor,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0 0 2.5px 2px',
            position: 'relative'
        },
        activeDotPulse: {
            position: 'absolute',
            top: -1,
            left: -3,
            height: 6.5,
            width: 6.5,
            background: theme.color.themeColor,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0 0 2.5px 2px',
            animationName: [translateKeyframes],
            animationDuration: '1.8s, 1200ms',
            animationTimingFunction: 'ease-out',
            animationIterationCount: 'infinite'
        },
        dot: {
            height: 5,
            width: 5,
            background: 'transparent',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0 0 2.25px 2px'
        },
        profileWrapper: {
            background: theme.color.compColor,
            height: 50,
            margin: '0px 17px 10px 17px',
            transition: 'all .2s ease-in-out',
            borderRadius: 5,
            boxShadow: theme.color.boxShadowCorner,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-height:800px)': {
                height: 40,
                margin: '0px 17px 5px 17px'
            }
        },
        avatarWrapper: {
            height: 50,
            width: 16,
            background: theme.color.themeColor,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '@media(max-height:800px)': {
                height: 40
            }
        },
        avatar: {
            borderRadius: '50%',
            border: '2px solid' + theme.color.themeColor,
            background:
                theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                    ? theme.color.compColor
                    : theme.color.buttonFontColor,
            display: 'flex',
            height: 25,
            width: 25,
            marginRight: -11,
            alignItems: 'center',
            justifyContent: 'center'
        },
        avatarName: {
            display: 'inline-block',
            padding: '0 6px',
            color: theme.color.fontColor,
            fontWeight: 400,
            fontSize: 12,
            lineHeight: 1.4,
            marginLeft: 12,
            '@media(max-height:800px)': {
                fontSize: 10,
                lineHeight: 1.1
            }
        },
        avatarType: {
            color: theme.color.subFontColor,
            fontWeight: 400,
            fontSize: 11,
            '@media(max-height:800px)': {
                fontSize: 9
            }
        }
    });

